<template>
  <v-dialog
    v-model="transactionAllocationDialog[transaction.transaction_id]"
    max-width="1080px"
    :key="transaction.transaction_id"
  >
    <v-card>
      <AllocationStepper
        :transaction="transaction"
        @passModalClose="passModalClose($event)"
      />
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AllocationModal",
  components: {
    AllocationStepper: () => import("./AllocationStepper.vue"),
  },
  props: ["transaction", "transactionAllocationDialog"],
  data() {
    return {};
  },
  methods: {
    passModalClose(event) {
      this.$emit("passModalClose", event);
    },
  },
};
</script>